import axios from 'axios'

export function getYSYToken(params) { // 获取萤石云Token
  const appKey = params.appKey
  const appSecret = params.appSecret
  const query = `appKey=${appKey}&appSecret=${appSecret}`
  return new Promise((resolve, reject) => {
    axios({
      method: 'post',
      'Content-Type': 'application/x-www-form-urlencoded',
      url: `https://open.ys7.com/api/lapp/token/get?${query}`
    }).then(res => {
      resolve(res.data)
    })
  })
}

export function controlYSY(params) { // 控制萤石云摄像头
  const accessToken = params.accessToken
  const deviceSerial = params.deviceSerial
  const channelNo = params.channelNo
  const direction = params.direction
  const speed = params.speed
  const query = `accessToken=${accessToken}&deviceSerial=${deviceSerial}&channelNo=${channelNo}&direction=${direction}&speed=${speed}`
  return new Promise((resolve, reject) => {
    axios({
      method: 'post',
      'Content-Type': 'application/x-www-form-urlencoded',
      url: `https://open.ys7.com/api/lapp/device/ptz/start?${query}`
    }).then(res => {
      resolve(res.data)
    })
  })
}

export function stopControlYSY(params) { // 控制萤石云摄像头
  const accessToken = params.accessToken
  const deviceSerial = params.deviceSerial
  const channelNo = params.channelNo
  const direction = params.direction
  const query = `accessToken=${accessToken}&deviceSerial=${deviceSerial}&channelNo=${channelNo}&direction=${direction}`
  return new Promise((resolve, reject) => {
    axios({
      method: 'post',
      'Content-Type': 'application/x-www-form-urlencoded',
      url: `https://open.ys7.com/api/lapp/device/ptz/stop?${query}`
    }).then(res => {
      resolve(res.data)
    })
  })
}
